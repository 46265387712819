import { IMovement, ITrip, Movement } from "navision-proxy-api/@types/terminal";
import React from "react";
import { AlertedMovement } from "../context/trips";
import { TripTable } from "../containers/TripTable";
import { getShipmentKey, useStore } from "../context/store";
import { Box } from "@material-ui/core";
import { INAVShipmentLog } from "navision-proxy-api/@types/navTypes";

interface IAlertedMovementsTableProps {
  alertedMovements: AlertedMovement[];
  saveLine: (movement: IMovement) => void;
}

export const AlertedMovementsTable = ({
  alertedMovements,
  saveLine
}: IAlertedMovementsTableProps) => {
  //we need to group movements into "trip" with alerted movements to work with tre trip table
  const alertedTrip: ITrip = {
    PartialTripNr: "Alerted Trip",
    Lines: alertedMovements as Movement[]
  };

  console.debug("alertedMovements", alertedMovements);

  const { alertedShipments } = useStore();

  console.debug("alerted shipment logs", alertedShipments);

  const unalertTrip = (trip: ITrip) => {
    //save ualeted shipment in the client memory
    let savedMovement: Movement | undefined = undefined;
    console.debug("unalerting trip:", trip);
    console.debug(
      "unalerting trip:old alerted shipment logs",
      alertedShipments
    );
    const newAlertedShipments = alertedShipments.current.map(
      alertedShipment => {
        const shipment = trip.Lines.find(
          (line: AlertedMovement) =>
            alertedShipment.log?.Entry_No == line.alertId
        );
        if (shipment && (shipment as any).alertApproved) {
          savedMovement = shipment;
          console.debug("unalerting shipment", shipment);
          //remove from old trip ids memory
          return { ...alertedShipment, alertApproved: true }; //remove from alerted shipments
        }
        return alertedShipment;
      }
    );
    console.debug("new alerted shipment logs", newAlertedShipments);
    alertedShipments.current = newAlertedShipments;
    //this will trigger trips rerender
    if (savedMovement) {
      saveLine(savedMovement);
    }
  };

  return (
    <Box key={`material-table-${alertedMovements.length}`}>
      <TripTable
        trip={alertedTrip}
        alwaysSaveOnUpdate
        alertApprovedMode
        saveTrip={unalertTrip}
        hideTripActions
        hidePdfAction
      />
    </Box>
  );
};
