import React, { useState, useEffect } from "react";

import { untouchShipments } from "../api";

import { updateTrips } from "../api";

import { IMovement, ITrip, Movement } from "navision-proxy-api/@types/terminal";
import _ from "lodash";
import { useSnackbar } from "../context/snackbar";

interface ITripsApiActions {
  updateTrip: (trip: ITrip, updateAll?: boolean) => Promise<void>;
  /** Send Update trip request on the backend */
  updateApproveTripLines: (
    partialTripNr: string,
    linesToUpdate?: Movement[],
    linesToApprove?: Movement[]
  ) => Promise<Movement[] | null>;
  unApproveLines: (lines: IMovement[]) => Promise<void>;
}

function useTripsApiActions(): ITripsApiActions {
  const { openSnackbar } = useSnackbar();

  const updateTrip = async (trip: any, updateAll = false) => {
    console.log("updating trip in actions with", trip);
    try {
      await updateTrips({
        ...trip,
        Lines: trip.Lines.filter(({ isNew }: any) => updateAll || isNew)
      });
    } catch (err: any) {
      openSnackbar(`Update trip ${err.message}`, "error");
    }
  };

  /** updates and updates + approve provided lines */
  const updateApproveTripLines = async (
    partialTripNr: string,
    linesToUpdate: Movement[] = [],
    linesToApprove: Movement[] = []
  ) => {
    //merge two lists
    const allLines = [...linesToUpdate];
    linesToApprove.forEach(lineToApprove => {
      const exitingLine = allLines.find(
        l => l.ShipmentNr == lineToApprove.ShipmentNr
      );
      if (!exitingLine) {
        //line isnt in lines to update
        //line already there
        allLines.push({
          ...lineToApprove,
          touchedDate: new Date().toISOString(),
          isNew: true
        });
      } else {
        //line is already in lines to update list
        exitingLine.isNew = true;
        exitingLine.touchedDate = new Date().toISOString();
      }
    });

    try {
      await updateTrips({
        PartialTripNr: partialTripNr,
        Lines: allLines
      });
      return allLines;
    } catch (err: any) {
      openSnackbar(
        `Update trip error for trip nr:${partialTripNr}, line nr.:${allLines
          .map((_, i) => i)
          .join("m")}. Error: ${err.message}`,
        "error"
      );
      return null;
    }
    // we dont want ot rerender the whole thing
    //setShipmentsData(newPlans);
  };

  const unApproveLines = async (lines: IMovement[]) => {
    await untouchShipments(lines);
  };

  return { updateTrip, updateApproveTripLines, unApproveLines };
}

export { useTripsApiActions };
