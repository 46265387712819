import React, { useState, useEffect } from "react";
import {
  fetchPlans,
  fetchUnits,
  fetchPackages,
  checkShipmentsAlerts
} from "../api";

import { getShipmentKey, getShipmentTripNr, useStore } from "./store";

import {
  IMovement,
  IPlan,
  ITrip,
  Movement
} from "navision-proxy-api/@types/terminal";
import _ from "lodash";
import { useSnackbar } from "./snackbar";
import { highlightText, removeHighlights } from "../utils/highlightText";
import { IVisibilitySetting, useViewSettings } from "./viewSettings";
import { DEFAULT_DATE_FILTER } from "../config/app";

/** This context should be used for Dashboard only
 * If using in other small components it leads to frequent rerenders
 */

// type TripExtended = ITrip & {
//   //for alerted movements
//   oldTrip?: string;
//   newTrip?: string;
// }

export type AlertedMovement = Movement & {
  oldTrip?: string;
  newTrip?: string;
  suggestedGate?: string;
  alertReason?: string;
  alertApproved?: boolean;
  alertId?: string;
};

interface ITripsContext {
  trips: ITrip[];
  /** Loading trips */
  loading: boolean;
  dateFilter: Date;
  changeDateFilter: (newDate: Date) => void;
  search: string;
  applySearchHighlight: () => void;
  isSearchable: (searchValue?: string) => boolean;
  changeSearch: (newSeatch: string) => void;
  /** Main loader */
  loadPlans: ({ ignoreCache }: { ignoreCache?: boolean }) => void;
  /** Save trip to the state. Use wise to not cause unwanted rerender */
  saveTrip: (trip: ITrip) => void;
  /** Save line to the state. Use wise to not cause unwanted rerender  */
  saveLine: (line: Movement) => void;
  /** Save unapproving to the state */
  saveUntouchLine: (line: IMovement) => void;
  /** Save approve of the trip to the state */
  saveApproveTrip: (trip: ITrip) => void;
}

const Trips = React.createContext<ITripsContext>({} as ITripsContext);

function TripsProvider(props: {
  children: React.ReactElement;
}): React.ReactElement {
  /** dependencies */
  const {
    initVisibleSubdepartments,
    showAll,
    visiblePlans,
    //visibleSubdepartments,
    setVisiblePlans,
    lastLoadedPackages,
    initPackages,
    isTripsReversed,
    setUsedPackages,
    filterMode,
    sortTripsBy,
    showAlertMode
  } = useViewSettings();

  /** State */

  /** All loaded shipments data from the API */
  const [shipmentsData, setShipmentsData] = useState<IPlan[]>([]);

  const [search, setSearch] = useState("");
  const isSearchable = (searchValue = search) => searchValue.length > 2;

  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(DEFAULT_DATE_FILTER);
  //const [dateFilter, setDateFilter] = useState();

  const { openSnackbar } = useSnackbar();

  const {
    //editedTrips,
    initEditedTripsFromPlans,
    alertedShipments,
    checkTripsInserts
  } = useStore();

  const computeSearchedTrips = (trips: ITrip[]) => {
    if (isSearchable()) {
      console.debug("searching trips");
      const filteredTrips = trips.filter(
        ({
          PartialTripNr,
          Vehicle,
          Trailer,
          StartTime,
          ShipmentUnits,
          Lines
        }) =>
          PartialTripNr?.toLowerCase().includes(search) ||
          Vehicle?.toLowerCase().includes(search) ||
          Trailer?.toLowerCase().includes(search) ||
          StartTime?.toLowerCase().includes(search) ||
          Object.entries(ShipmentUnits).some(([key, value]) =>
            `${key}${value}`.includes(search)
          ) ||
          Lines.some(line => {
            //search by movements address
            return (
              line.SenderName?.toLowerCase()
                .latinise()
                .includes(search.latinise()) ||
              line.AddresseeName?.toLowerCase()
                .latinise()
                .includes(search.latinise()) ||
              (line.ShipmentNr &&
                String(line.ShipmentNr)?.toLowerCase().includes(search)) ||
              (line as any).ShipmentRemark?.latinise()
                .toLowerCase()
                .includes(search.latinise()) ||
              (line as any).BrandAndNo?.latinise()
                .toLowerCase()
                .includes(search.latinise()) ||
              (line as any).PlanInfo?.latinise()
                .toLowerCase()
                .includes(search.latinise())
            );
          })
      );
      console.debug("filtered trips", filteredTrips);
      return filteredTrips;
    } else {
      return trips;
    }
  };

  const computeSortedTrips = (
    trips: ITrip[],
    visibleSubdepartments: IVisibilitySetting,
    editedTrips: string[]
  ) => {
    const sortBy = sortTripsBy as keyof ITrip;
    const computedTrips: ITrip[] = trips.sort((a: ITrip, b: ITrip) => {
      if (
        editedTrips.includes(a.PartialTripNr) &&
        editedTrips.includes(b.PartialTripNr)
      ) {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else if (
        editedTrips.includes(a.PartialTripNr) &&
        !editedTrips.includes(b.PartialTripNr)
      ) {
        return 1;
      } else if (
        !editedTrips.includes(a.PartialTripNr) &&
        editedTrips.includes(b.PartialTripNr)
      ) {
        return -1;
      } else {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      }
    }); //sort by trip number

    console.debug(computedTrips);
    if (isTripsReversed) {
      computedTrips.reverse();
    }

    console.debug("computed trips", computedTrips);
    return computedTrips;
  };

  /** We zip alerted movements into trip it works well with the structure of the app */
  const computeAlertedMovementsTrip = (trips: ITrip[]) => {
    //we get it directly from the local store because it is not being updated through state so outdated version is here if taken from useStore

    console.debug("trips", trips);

    //here should be zipping of alerted movements with exitsting movements
    const lines = trips.map(({ Lines }) => Lines).flat();
    console.debug("flatten lines", lines);
    console.debug("alerted shipments", alertedShipments.current);

    const alertedLines = alertedShipments.current
      .map(alertedShipment => {
        const line = lines.find(line =>
          getShipmentKey(alertedShipment.log).includes(String(line.ShipmentNr))
        );
        console.debug("line", line);

        if (line) {
          const newTripNr = getShipmentTripNr(alertedShipment.log);

          const newTrip = trips.find(
            ({ PartialTripNr }) => PartialTripNr === newTripNr
          );
          const suggestedGate = newTrip?.Gates;
          return {
            ...line,
            oldTrip: alertedShipment.oldShipmentData?.PartialTripNr,
            oldGate: alertedShipment.oldShipmentData?.ActualGate,
            newTrip: newTripNr,
            suggestedGate,
            alertReason: alertedShipment.alertReason,
            alertApproved: alertedShipment.alertApproved,
            alertId: alertedShipment.log?.Entry_No
          } as AlertedMovement;
        } else {
          return undefined;
        }
      })
      .filter(line => line && line.alertReason && line.alertApproved !== true);

    // lines
    //   .map((line, index, arr) => {
    //     //zip lines to alerts
    //     const lineLog = alertedShipments.current.find(alertedShipment =>
    //       getShipmentKey(alertedShipment.log).includes(String(line.ShipmentNr))
    //     );

    //     if (lineLog) {
    //       console.debug("line log for line", line, lineLog);

    //       const newTripNr = getShipmentTripNr(lineLog.log);

    //       const newTrip = trips.find(
    //         ({ PartialTripNr }) => PartialTripNr === newTripNr
    //       );
    //       const suggestedGate = newTrip?.Gates;
    //       return {
    //         ...line,
    //         oldTrip: lineLog.oldShipmentData?.PartialTripNr,
    //         oldGate: lineLog.oldShipmentData?.ActualGate,
    //         newTrip: newTripNr,
    //         suggestedGate,
    //         alertReason: lineLog.alertReason,
    //         alertApproved: lineLog.alertApproved
    //       } as AlertedMovement;
    //     } else {
    //       return line;
    //     }
    //   })
    //   .filter(
    //     //filter not alerted lines
    //     (line: AlertedMovement) =>
    //       line.alertReason && line.alertApproved !== true
    //   );

    console.debug("alerted lines", alertedLines);

    return { Lines: alertedLines, PartialTripNr: "Alerted Lines" } as ITrip;
  };

  //compute trips
  const computeTrips = () => {
    console.debug("computing trips");
    const visibleSubdepartments = initVisibleSubdepartments(shipmentsData);
    const editedTrips = initEditedTripsFromPlans(shipmentsData);

    const filteredTrips: ITrip[] = shipmentsData
      .filter(({ Id }) => visiblePlans[Id])
      .reduce((acc, { Trips }) => [...acc, ...Trips], [] as ITrip[])
      .filter(
        ({ Subdepartment }) => visibleSubdepartments[Subdepartment || ""]
      );

    //const filteredLines = filteredTrips.map(trip => trip.Lines).flat();

    // approvedMovementsRef.current = filteredLines.filter(
    //   line => line.touchedDate
    // );

    // /** write old trip numbers in the local storage */
    // const latestMovementTripNr: { [shipmentNr: string]: string } = JSON.parse(
    //   localStorage.getItem("latestMovementTripNr") || "{}"
    // );

    // filteredLines.forEach(line => {
    //   //we remembering old trip nr for each shipment
    //   if (!latestMovementTripNr[String(line.ShipmentNr)]) {
    //     latestMovementTripNr[String(line.ShipmentNr)] = line.PartialTripNr;
    //   }
    // });
    // localStorage.setItem(
    //   "latestMovementTripNr",
    //   JSON.stringify(latestMovementTripNr)
    // );

    const searchedTrips: ITrip[] = computeSearchedTrips(filteredTrips);

    if (showAlertMode) {
      return [computeAlertedMovementsTrip(searchedTrips)];
    }
    if (showAll) {
      //we divide into movements and do the sorting inside AllMovementsTable
      return searchedTrips;
    }
    //regilars movements by trips view
    return computeSortedTrips(
      searchedTrips,
      visibleSubdepartments,
      editedTrips
    );
  };

  /** Visible trips after filtering */
  //const [trips, setTrips] = useState<ITrip[]>([]);
  const trips: ITrip[] = React.useMemo<ITrip[]>(() => {
    // if (search.length == 0 || isSearchable()) {
    console.log("compute trips hook trigereeed");
    const computedTrips = computeTrips();

    return computedTrips;
    // }
    // return trips || [];
  }, [
    shipmentsData,
    visiblePlans,
    //visibleSubdepartments,
    isTripsReversed,
    //editedTrips,
    showAll,
    showAlertMode,
    search.length == 0 || isSearchable() ? search : null, //ignore reload when search is still entering,
    //search,
    sortTripsBy
  ]);

  /** Effects */

  //init load
  useEffect(() => {
    if (!localStorage.getItem("userId")) {
      //user login
      openSnackbar("Please relogin", "error");
      localStorage.clear();
      return;
    }
    if (dateFilter && !isNaN(dateFilter as any)) {
      loadPlans();
    }
  }, [dateFilter?.toDateString(), filterMode]);

  const lastFetchPromiseRef = React.useRef<Promise<any> | null>(null);
  const lastCheckInsertsPromiseRef = React.useRef<Promise<any> | null>(null);

  /** TODO: this function is problematic because it triggeres a lot of state changes
   *  which lead to uneeded rerendering the whole thing multiple times */
  const loadPlans = async (
    { ignoreCache } = {
      ignoreCache: false
    }
  ) => {
    setLoading(true);
    try {
      console.log("fetching plans");

      console.log(
        "address settings",
        localStorage.getItem("departmentAdresses")
      );
      console.debug("loading plans ");

      const currentFetchPromise = fetchPlans({
        dateFilter: getFormattedDateFilter(),
        ignoreCache,
        filterMode
      } as any);

      lastFetchPromiseRef.current = currentFetchPromise;
      //lastCheckInsertsPromiseRef.current = currentCheckInsertsPromise;
      const [data] = await Promise.all([lastFetchPromiseRef.current]);

      //avoid rased condition
      if (lastFetchPromiseRef.current == currentFetchPromise) {
        const plans = data.Plans;

        const packages = data.packages;

        const usedPackages = data.usedPackages;

        //we want to save used packages for export
        setUsedPackages(usedPackages);

        //we want to save packages to be able to re-init them with new lang or updated hidden settings
        lastLoadedPackages.current = packages;

        //pass used packages directly to the function to avoid state update
        initPackages(packages, usedPackages);

        setShipmentsData(plans);

        //init visible plans
        const newVisiblePlans = {
          ...plans.reduce(
            (acc: any, { Id }: any) => ({ ...acc, [Id]: true }),
            {}
          )
        };

        Object.keys(visiblePlans).forEach(key => {
          if (newVisiblePlans[key]) {
            newVisiblePlans[key] = visiblePlans[key];
          }
        });
        setVisiblePlans(newVisiblePlans);
      }

      if (showAlertMode) {
        //awaiting because we need to show it instantly
        await checkTripsInserts(getFormattedDateFilter());
      } else {
        //not awaiting because we dont need it
        checkTripsInserts(getFormattedDateFilter());
      }
    } catch (err: any) {
      console.error(err);
      openSnackbar(`Error loading plans: ${err.message}`, "error");
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   //init filters that are depended on a shipments api data
  //   //this will trigger computing trips
  // }, [shipmentsData]);

  // //compute trips when change global settings
  // useEffect(() => {
  //   if (search.length == 0 || isSearchable()) {
  //     //ignore reload when search is still entering
  //     computeTrips();
  //   }
  // }, [
  //   visiblePlans,
  //   visibleSubdepartments,
  //   isTripsReversed,
  //   editedTrips,
  //   showAll,
  //   search
  // ]);

  const applySearchHighlight = () => {
    console.debug("trips:applying search highlight");

    const highlightZone = document.getElementById("trips-data");
    //we dont want to use state because this method can be memoized
    const searchValue = (document.getElementById("search") as any)?.value;
    if (isSearchable(searchValue)) {
      highlightText(searchValue, highlightZone);
    } else {
      removeHighlights(highlightZone);
    }
  };

  //search highlight
  useEffect(() => {
    applySearchHighlight();
  }, [trips]);

  // const loadPackages = async () => {
  //   try {
  //     const packages = await fetchPackages();
  //     setPackages(packages);
  //   } catch (err) {
  //     console.error(err);
  //     setError(`Error loading profiles: ${err}`);
  //   }
  // };

  /** ACTIONS */

  const changeDateFilter = (date: any) => {
    setDateFilter(date);
  };

  const changeSearch = (value: any) => {
    const searchValue = value.toLowerCase();
    setSearch(searchValue);
  };

  const getFormattedDateFilter = () => {
    let year = dateFilter.getFullYear();
    let month = (1 + dateFilter.getMonth()).toString().padStart(2, "0");
    let day = dateFilter.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  //TOFIX: Save trip does not trigger calculate trips
  //upd: it seems to be triggered by shipmentsData
  // Lately it was triggered because initEditedTripsFromPlans was running but now after redudent reruns are disabled nothing is triggering it
  const saveTrip = (trip: ITrip) => {
    // update gate
    trip.Gates = trip.Lines.reduce((acc: any, { ActualGate }) => {
      if (acc.includes(ActualGate) || !ActualGate || ActualGate == "0") {
        return acc;
      } else {
        return [...acc, ActualGate];
      }
    }, []).join(",");

    const newPlans = JSON.parse(JSON.stringify(shipmentsData));
    Object.assign(
      newPlans
        .find(({ Id }: any) => Id == parseInt(trip.Lines[0].PlanDepartment))
        .Trips //find trip
        .find(({ PartialTripNr }: any) => PartialTripNr === trip.PartialTripNr),
      JSON.parse(JSON.stringify(trip))
    );
    console.log("setting shipments data");
    setShipmentsData(newPlans);
  };

  const saveLine = (line: Movement) => {
    const newPlans = JSON.parse(JSON.stringify(shipmentsData));
    const newLines = newPlans
      .find(({ Id }: any) => Id == parseInt(line.PlanDepartment))
      .Trips.find(
        ({ PartialTripNr }: any) => PartialTripNr === line.PartialTripNr
      ).Lines;

    const updatedLine = line;

    newLines[
      newLines.findIndex(
        ({ ShipmentNr }: any) => ShipmentNr === line.ShipmentNr
      )
    ] = updatedLine;

    setShipmentsData(newPlans);
  };

  const saveUntouchLine = (line: any) => {
    const newPlans = JSON.parse(JSON.stringify(shipmentsData));
    newPlans
      .find(({ Id }: any) => Id == parseInt(line.PlanDepartment))
      .Trips //find trip
      .find(({ PartialTripNr }: any) => PartialTripNr === line.PartialTripNr)
      .Lines.find(
        ({ ShipmentNr }: any) => ShipmentNr == line.ShipmentNr
      ).touchedDate = false;

    setShipmentsData(newPlans);
  };

  const saveApproveTrip = async (trip: any) => {
    const newPlans = JSON.parse(JSON.stringify(shipmentsData));
    //we need to update visible trips without refetching them
    Object.assign(
      newPlans
        .find(({ Id }: any) => Id == parseInt(trip.Lines[0].PlanDepartment))
        .Trips //find trip
        .find(({ PartialTripNr }: any) => PartialTripNr === trip.PartialTripNr)
        .Lines,
      JSON.parse(
        JSON.stringify(
          trip.Lines.map((line: any) => ({
            ...line,
            touchedDate: line.isNew || line.touchedDate ? new Date() : ""
          }))
        )
      )
    );

    setShipmentsData(newPlans);
  };

  return (
    <Trips.Provider
      value={{
        trips: trips,
        loading: loading,
        dateFilter: dateFilter,
        changeDateFilter: changeDateFilter,
        search: search,
        applySearchHighlight,
        isSearchable,
        changeSearch: changeSearch,
        loadPlans: loadPlans as any,
        saveTrip: saveTrip,
        saveLine,
        saveUntouchLine: saveUntouchLine,
        saveApproveTrip
      }}
      {...props}
    />
  );
}

const useTrips = () => React.useContext(Trips);

export { useTrips, TripsProvider };
