import React from "react";
import createPersistedState from "use-persisted-state";
import _ from "lodash";

import { checkShipmentsAlerts } from "../api";
import {
  IPlan,
  ITrip,
  Movement,
  ShipmentAlert
} from "navision-proxy-api/@types/terminal";
import { INAVShipmentLog } from "navision-proxy-api/@types/navTypes";

import { useSnackbar } from "./snackbar";
import moment from "moment";
import { useLang } from "./lang";
import { DEFAULT_DATE_FILTER } from "../config/app";

interface IMovementsConfirmContext {
  selectedMovements: Movement[];
  selectMovements: React.Dispatch<React.SetStateAction<Movement[] | undefined>>;
  test: string;
}

const MovementsConfirmContext = React.createContext<IMovementsConfirmContext>(
  {} as IMovementsConfirmContext
);

/** Global app settings */
function MovementsConfirmProvider(props: any) {
  const [selectedMovements, setSelectedMovements] =
    React.useState<Movement[]>();
  const { t } = useLang();

  //const selectMovements = React.useCallback(setSelectedMovements, []);
  const selectMovements = React.useCallback(setSelectedMovements, []);

  return (
    <MovementsConfirmContext.Provider
      value={{
        selectedMovements,
        selectMovements
        // setAlertedShipments
      }}
      {...props}
    />
  );
}

const useMovementsConfirm = () => React.useContext(MovementsConfirmContext);

export { useMovementsConfirm, MovementsConfirmProvider };
