import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useMovementsConfirm } from "../../context/movementsConfirm";
import { Typography } from "@material-ui/core";
import { useLang } from "../../context/lang";
import MovementsTable from "./ReceiveMovements";
import ReceiveMovements from "./ReceiveMovements";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80vw"
  }
}));

export default () => {
  const classes = useStyles();
  const { selectedMovements, selectMovements } = useMovementsConfirm();
  const { t } = useLang();

  //const driver = selectedMovements?.map(m => m.Driver).join(", ");

  const isOpen = selectedMovements?.length > 0;

  const handleClose = () => {
    selectMovements([]);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <>
            <div className={classes.paper}>
              {" "}
              <Typography variant="h4" id="transition-modal-title">
                {t("receiveShipment")}
              </Typography>
              <ReceiveMovements />
            </div>
          </>
        </Fade>
      </Modal>
    </div>
  );
};
