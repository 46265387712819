import React from "react";
import MaterialTable, {
  Column,
  Components,
  MaterialTableProps,
  MTable,
  MTableAction,
  MTableToolbar
} from "material-table";
import { useMovementsConfirm } from "../../context/movementsConfirm";
import { useLang } from "../../context/lang";
import { Movement } from "navision-proxy-api/@types/terminal";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTripsApiActions } from "../../hooks/useTripsApiActions";
import { set } from "lodash";
import { Loader } from "../../components/Loader";
import { registerContainer } from "../../api";

const useStyles = makeStyles((theme: any) => ({
  receiveMovements: {
    "& .MuiButton-containedSizeLarge": {
      margin: "10px",
      padding: "10px 20px",
      fontSize: "1.3rem",
      width: "400px",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    }
  }
}));

export type PackageContainerInfo = {
  packageName: string;
  packageKey: string;
  in: number;
  out: number;
};

const getMainColumns = (t: any): Column<Movement>[] => {
  return [
    {
      title: t("ShipmentNr"),
      field: "ShipmentNr",
      hidden: true,
      editable: "never",
      sorting: false
    },
    {
      title: t("SenderName"),
      field: "SenderName",
      hidden: false,
      editable: "never",
      sorting: false
    },
    {
      title: t("AddresseeName"),
      field: "AddresseeName",
      hidden: false,
      editable: "never",
      sorting: false
    },
    {
      title: t("LoadedQty"),
      field: "LoadedQty",
      hidden: false,
      editable: "never",
      sorting: false,
      type: "numeric"
    },
    {
      title: "Unit",
      field: "Unit",
      hidden: false,
      editable: "never",
      sorting: false
    },
    {
      title: t("T6 CC"),
      field: "Packages.T6 CC",
      hidden: false,
      editable: "always",
      type: "numeric"
    },
    {
      title: t("HYLDER"),
      field: "Packages.HYLDER",
      hidden: false,
      editable: "always",
      type: "numeric"
    },
    {
      title: t("FORL&dot;"),
      field: "Packages.FORL&dot;",
      hidden: false,
      width: 70,
      editable: "always",
      sorting: false,
      emptyValue: "0",
      type: "numeric"
    }
  ];
};

const getTotalColumns = (t: any): Column<PackageContainerInfo>[] => {
  return [
    {
      title: "",
      field: "packageName",
      hidden: false,
      editable: "never"
    },
    {
      title: t("in"),
      field: "in",
      hidden: false,
      editable: "never",
      type: "numeric"
    },
    {
      title: t("out"),
      field: "out",
      hidden: false,
      editable: "always",
      type: "numeric"
    }
  ];
};

const getTotalData = (
  movements: Movement[],
  t: any
): PackageContainerInfo[] => {
  const totalIn = {
    "T6 CC": 0,
    HYLDER: 0,
    "FORL&dot;": 0
  };
  movements.forEach(movement => {
    totalIn["T6 CC"] += parseInt(String(movement.Packages["T6 CC"] || "0"));
    totalIn["HYLDER"] += parseInt(String(movement.Packages["HYLDER"] || "0"));
    totalIn["FORL&dot;"] += parseInt(
      String(movement.Packages["FORL&dot;"] || "0")
    );
  });

  return Object.entries(totalIn)?.map(([packageName, inValue]) => ({
    packageName: t(`${packageName}`),
    packageKey: packageName,
    in: inValue,
    out: 0
  }));
};

//material table hack to keep table in edit mode
function bulkEditPatch<DataType extends object>(
  submitRef: any,
  startEditRef: any,
  onSubmit: (
    editedData: Record<
      number,
      {
        oldData: DataType;
        newData: DataType;
      }
    >
  ) => void
): {
  editComponents: Components;
  editableActions: MaterialTableProps<DataType>["editable"];
  editableOptions: MaterialTableProps<DataType>["options"];
  // editAllAction: () => void;
  //submit: () => void;
} {
  //let editAllAction: () => void;
  //let saveAllAction: () => void;

  const editComponents: Components = {
    // Action: props => {
    //   console.log("rendering action");
    //   if (props.action.tooltip === "Edit All") {
    //     //kinda hack for always keeping table in edit mode
    //     const EditButton = () => {
    //       React.useLayoutEffect(() => {
    //         console.log("cli9cking edit all");
    //         console.log(
    //           tableRef.current,
    //           !tableRef.current["dataManager"]["bulkEditOpen"],
    //           editAllAction
    //         );
    //         if (
    //           tableRef.current &&
    //           !tableRef.current["dataManager"]["bulkEditOpen"] &&
    //           editAllAction
    //           // !adding &&
    //           // !editing
    //         ) {
    //           console.log("clicking!!");
    //           editAllAction();
    //         }
    //       }, []);
    //       return null;
    //     };
    //     return <EditButton />;
    //   }
    //   return <MTableAction {...props} />;
    // },
    Toolbar: (
      props //we need to render for add actions in toolbar but not display it
    ) => {
      // addAction = props.actions.find(
      //   ({ tooltip }) => tooltip === "Add"
      // ).onClick;

      startEditRef.current = props.actions.find(
        ({ tooltip }: { tooltip: string }) => tooltip === "Edit All"
      )?.onClick;
      submitRef.current = props.actions.find(
        ({ tooltip }: { tooltip: string }) => tooltip === "Save all changes"
      )?.onClick;
      // discardAllAction = props.actions.find(
      //   ({ tooltip }) => tooltip === "Discard all changes"
      // ).onClick;
      return (
        <Box display={"none"}>
          <MTableToolbar {...props} />
        </Box>
      );
    }
  } as Components;
  const editableActions = {
    onBulkUpdate: async (
      editedData: Record<
        number,
        {
          oldData: DataType;
          newData: DataType;
        }
      >
    ) => {
      onSubmit(editedData);
    }
  };
  return {
    editComponents,
    editableActions,
    editableOptions: { actionsColumnIndex: -2, toolbar: true }
  };
}

export default function ReceiveMovements() {
  const { selectedMovements, selectMovements } = useMovementsConfirm();
  const { updateApproveTripLines } = useTripsApiActions();
  const { t } = useLang();

  const [mainLoading, setMainLoading] = React.useState(false);
  const [totalLoading, setTotalLoading] = React.useState(false);

  const classes = useStyles();

  const tripNr = [
    ...new Set(selectedMovements?.map(m => m.PartialTripNr))
  ].join(", ");
  const vehicle = [...new Set(selectedMovements?.map(m => m.Vehicle))].join(
    ", "
  );

  const mainColumns = React.useMemo(() => getMainColumns(t), []);
  const totalColumns = React.useMemo(() => getTotalColumns(t), []);

  const totalData = React.useMemo(
    () => getTotalData(selectedMovements, t),
    [selectedMovements]
  );

  //MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it inside wrapper component or/and inside material table
  //hack for controlling table edit mode
  const submitMainRef = React.useRef<any>(null);
  const startEditMainRef = React.useRef<any>(null);

  const handleEditMain = (
    editedData: Record<
      number,
      {
        oldData: Movement;
        newData: Movement;
      }
    >
  ) => {
    setMainLoading(true);
    console.log("on packages submit", editedData);
    console.log("selected movements", selectedMovements);
    const newSelection = [...selectedMovements];
    Object.keys(editedData).forEach((key: string) => {
      newSelection[parseInt(key)] = editedData[parseInt(key)].newData;
    });
    selectMovements(newSelection);
    //do api call
    setMainLoading(false);
  };

  const editMainPatch = bulkEditPatch<Movement>(
    submitMainRef,
    startEditMainRef,
    handleEditMain
  );

  const submitTotalRef = React.useRef<any>(null);
  const startEditTotalRef = React.useRef<any>(null);

  const handleEditTotal = async (
    editedData: Record<
      number,
      {
        oldData: PackageContainerInfo;
        newData: PackageContainerInfo;
      }
    >
  ) => {
    setTotalLoading(true);
    console.log("on total submit", editedData);
    const containers: PackageContainerInfo[] = totalData.map(
      (packageInfo, i) => {
        if (editedData[i]) {
          return editedData[i].newData;
        } else {
          return packageInfo;
        }
      }
    );
    const result = await registerContainer(containers);
    setTotalLoading(false);
  };

  const editTotalPatch = bulkEditPatch<PackageContainerInfo>(
    submitTotalRef,
    startEditTotalRef,
    handleEditTotal
  );

  React.useEffect(() => {
    if (startEditMainRef.current) {
      startEditMainRef.current();
    }
    if (startEditTotalRef.current) {
      startEditTotalRef.current();
    }
  }, []);
  //END MATERIAL TABLE BULK EDIT PATCH
  //TODO: move it to wrapper componenet

  const handleReceive = () => {
    console.log("trigger submit");
    if (submitMainRef.current) submitMainRef.current();
    if (submitTotalRef.current) submitTotalRef.current();

    //triggerSubmitEdit();
  };

  return (
    <Box
      width={"100%"}
      display="flex"
      flexWrap={"wrap"}
      className={classes.receiveMovements}
    >
      <Box width={"100%"}>
        {tripNr && (
          <Box display={"flex"}>
            <Typography variant="subtitle1">
              <b>{`${t("tripNr")}:`}</b>
            </Typography>
            <Typography variant="body1">{tripNr}</Typography>
          </Box>
        )}
        {vehicle && (
          <Box display={"flex"}>
            <Typography variant="subtitle1">
              <b>{`${t("vehicle")}:`}</b>
            </Typography>
            <Typography variant="body1">{vehicle}</Typography>
          </Box>
        )}
      </Box>
      <Box width={"100%"} py={4}>
        <MaterialTable
          columns={mainColumns}
          data={selectedMovements}
          editable={editMainPatch.editableActions}
          options={{
            selection: false,
            toolbar: true, //for getting bulk action, later hide in components overwrite
            paging: false,
            minBodyHeight: "200px",
            maxBodyHeight: "70vh",
            ...editMainPatch.editableOptions
          }}
          components={{
            ...editMainPatch.editComponents
          }}
        />
      </Box>{" "}
      <Box
        width={{ xs: "100%", lg: "40%" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loader loading={mainLoading || totalLoading}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleReceive()}
          >
            {t("receive")}
          </Button>
          <Button variant="contained" color="primary" size="large">
            {t("receiveAndPrintLabel")}
          </Button>
        </Loader>
      </Box>
      <Box width={{ xs: "100%", lg: "60%" }} py={4}>
        <Typography variant="h4">{t("total")}</Typography>
        <Divider />
        <MaterialTable
          columns={totalColumns}
          editable={editTotalPatch.editableActions}
          components={{
            ...editTotalPatch.editComponents
          }}
          data={totalData}
          options={{
            selection: false,
            paging: false,
            ...editTotalPatch.editableOptions
          }}
        />
      </Box>
    </Box>
  );
}
